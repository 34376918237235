// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-markdowntemplate-js": () => import("./../src/components/markdowntemplate.js" /* webpackChunkName: "component---src-components-markdowntemplate-js" */),
  "component---src-pages-books-js": () => import("./../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-idea-blog-js": () => import("./../src/pages/idea/blog.js" /* webpackChunkName: "component---src-pages-idea-blog-js" */),
  "component---src-pages-idea-factfulness-js": () => import("./../src/pages/idea/factfulness.js" /* webpackChunkName: "component---src-pages-idea-factfulness-js" */),
  "component---src-pages-idea-inceptiondeck-js": () => import("./../src/pages/idea/inceptiondeck.js" /* webpackChunkName: "component---src-pages-idea-inceptiondeck-js" */),
  "component---src-pages-idea-saleswriting-js": () => import("./../src/pages/idea/saleswriting.js" /* webpackChunkName: "component---src-pages-idea-saleswriting-js" */),
  "component---src-pages-idea-scamper-js": () => import("./../src/pages/idea/scamper.js" /* webpackChunkName: "component---src-pages-idea-scamper-js" */),
  "component---src-pages-idea-swot-js": () => import("./../src/pages/idea/swot.js" /* webpackChunkName: "component---src-pages-idea-swot-js" */),
  "component---src-pages-idea-test-js": () => import("./../src/pages/idea/test.js" /* webpackChunkName: "component---src-pages-idea-test-js" */),
  "component---src-pages-idea-triz-js": () => import("./../src/pages/idea/triz.js" /* webpackChunkName: "component---src-pages-idea-triz-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

